<template>
  <div class="flix-text-center">
    <b>{{ data.booking_id }}</b>
    <qrCode :url="url" :link="''" />
  </div>
</template>
<script>
export default {
  components: {
    qrCode () { return import('@/components/embed/qrCode.vue') }
  },
  props: {
    data: Object
  },
  data () {
    return {
      url: false
    }
  },
  computed: {

  },
  mounted () {
    this.getUrl()
  },
  methods: {
    getUrl () {
      var url = this.$router.resolve({ name: 'checkIn', params: { id: this.data.booking_id + '.html' } })
      this.url = this.$flix_url + url.href
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
